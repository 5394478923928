import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend) // Use the HttpBackend plugin
    .use(initReactI18next)
    .init({
        lng: "ca",
        fallbackLng: "ca",
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        defaultNS: 'translation',
        backend: {
            loadPath: '/lang/{{lng}}/{{ns}}.json',
            // Example: '/lang/ca/common.json'
        },
    });

export default function GenericLayout({ children }) {

    return <>
        <ToastContainer
            limit={1}
        />
        {children}
    </>
}